.pedido-realizado {
  padding: 100px 0;
}

/* Desktop + Notbooks */
@media (min-width:1200px) {
  .pedido-realizado {
    display: flex;
    align-items: center;
    min-height: 95vh;
  }
}