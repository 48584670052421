.vdpArrowPrev:after {
  border-right-color: #59c229 !important;
}

.vdpArrowNext:after {
  border-left-color: #59c229 !important;
}

.vdpCell.selectable:hover .vdpCellContent,
.vdpCell.selected .vdpCellContent {
  background: #59c229 !important;
}

.vdpCell.today {
  color: #59c229 !important;
}

.vdpTimeUnit > input:hover,
.vdpTimeUnit > input:focus {
  border-bottom-color: #59c229 !important;
}