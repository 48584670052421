.main-carrinho {
  background-color: #ebdada;
}

.box_content {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 12px #999;
  padding: 20px 15px;
}

.titulo-principal {
  color: #000;
  font-size: 26px;
  font-weight: 700;
}

.subtitulo {
  color: #000;
  font-size: 16px;
  font-weight: 700;
}

.texto-destaque {
  color: #000;
  font-size: 16px;
  font-weight: 700;
}

.texto-mensagem {
  color: #999;
  font-size: 14px;
  font-weight: 400;
}

.texto {
  color: #666;
  font-size: 16px;
  font-weight: 400;
}

.texto-strong {
  color: #141414;
  font-size: 16px;
  font-weight: 500;
}

/* ### Table carrinho ### */
.table-cart-line {
  border-bottom: 1px solid #eee;
}
.table-cart-line td {
  min-height: 50px;
  border: none;
}
.table-cart-line .table-cart-data {
  text-align: center;
  vertical-align: middle;
}
.table-cart-data i {
  color: red;
}
.img-cart {
  width: 90px;
  height: 90px; 
}
.img-cart, .img-icon {
  margin-right: 15px;
}

.box_content-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-cupom {
  max-width: 50%;
  height: 40px;
  margin-right: 10px;
}

.cardLink {
  font-size: 14px;
  text-decoration: underline !important;
}
.cardLink:hover {
  text-decoration: none !important;
}

.form-single input, .form-radio {
  max-width: 240px;
}

.voltar a {
  text-decoration: underline !important;
}
.voltar a:hover {
  text-decoration: none !important;
}

.cart-links {
  text-decoration: underline !important;
  transition: all .2s ease-in;
}

.cart-links:hover {
  text-decoration: none !important;
}

.icon-edit {
  transition: all .1s ease-in;
}

.icon-edit:hover {
  transform: scale(1.2);
}

.btn-maisProdutos {
  margin-right: 10px;
}

.icon-copy {
  color: #999;
  font-size: 14px;
  margin-left: 5px;
}

/* Smartphones */
@media (max-width:767px) {
  .img-cart {
    width: 60px;
  }  
}