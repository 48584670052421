.status-novaConta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 20px;
}
.status-item {
  display: inline-block;
  position: relative;
  text-align: center;
}

.status-number {
  border: 2px solid #999;
  border-radius: 50%;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  width: 50px;
  height: 50px;
  margin: auto;
}

.status-number i {
  color: #146c43;
}

.status-item.active .status-number {
  border: 2px solid #146c43;
}

.status-text {
  font-size: 10px;
}

.login-label {
  color: #999;
  font-size: 14px !important;
  line-height: 2 !important;
}

/* ### Endereço ### */
.input-feedback span, .feedback {
  color: #999;
  font-size: 10px;
  font-style: italic;
}

.input-alert span {
  font-size: 12px;
  font-style: italic;
}

/* ### Pagto 2 ### */
.label-pagto2 {
  line-height: 1 !important;
}

/* Tablets */
@media (min-width:768px) and (max-width:991px) {
  .status-novaConta {
    margin: 10px 100px 20px;
  }
}

/* Desktop + Notbooks */
@media (min-width:1200px) {
  .pos-item::after {
    content: '';
    background: #999;
    display: block;
    width: 60px;
    height: 2px;
    position: relative;
    top: -50px;
    left: 60px;
  }
}