.status-novaConta {
  display: flex;
  align-items: center;
  justify-content: space-around !important;
  margin: 10px 0 20px;
}
.status-item {
  display: inline-block;
  position: relative;
  text-align: center;
}

.status-number {
  border: 2px solid #999;
  border-radius: 50%;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  width: 50px;
  height: 50px;
  margin: auto;
}

.status-number i {
  color: #146c43;
}

.status-item.active .status-number {
  border: 2px solid #146c43;
}

.status-text {
  font-size: 10px;
}

/* Desktop + Notbooks */
@media (min-width:1200px) {
  /* Com 3 Passos */
  /* .pos-item2::after {
    content: '';
    background: #999;
    display: block;
    width: 75px;
    height: 2px;
    position: relative;
    top: -50px;
    left: 90px;
  } */
  /* Com 4 Passos */
  .pos-item2::after {
    content: '';
    background: #999;
    display: block;
    width: 60px;
    height: 2px;
    position: relative;
    top: -50px;
    left: 60px;
  }
  .status-novaConta {
    justify-content: space-between !important;
  }
}