.input-group-text {
  height: 40px;
  width: 40px;
}

.input-icone {
  border-radius: 0px 4px 4px 0px !important;
}

.btn-openModal {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none;
  outline: none;
  text-decoration: underline !important;
}
.btn-openModal:hover {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none;
  outline: none;
  text-decoration: none !important;
}
.modal-header {
  border-bottom: none !important;
  justify-content: center !important;
}
.close {
  display: none !important;
}